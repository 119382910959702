body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.table td.fit,
.table th.fit {
  white-space: nowrap;
  width: 1%;
}

.dots {
  margin: 40px auto;
}

.dot {
  display: inline-block;
  border-radius: 50%;
  width: 50%;
  background-color: #EEE;
  height: 15px;
  width: 15px;
  margin: 0 2px;
}
.dot.dot-success {
  background-color: #449D44;
}
.dot.dot-warning {
  background-color: #F0AD4E;
}
.dot.dot-danger {
  background-color: #D9534F;
}
.dot.dot-sm {
  height: 10px;
  width: 10px;
}
.dot.dot-lg {
  margin: 0 4px;
  height: 20px;
  width: 20px;
}

@media screen {
  #printSection {
    display: none;
  }
}

@media print {
  body * {
    visibility:hidden;
    font-size: 105%;
  }
  #printSection, #printSection * {
    visibility:visible;
  }
  #printSection {
    position:absolute;
    left:0;
    top:0;
  }
}

.react-calendar {
  line-height: 0.2em;
}

/* Sticky footer styles
-------------------------------------------------- */
html {
  position: relative;
  min-height: 100%;
}
body {
  /* Margin bottom by footer height */
  margin-bottom: 60px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}


/* Custom page CSS
-------------------------------------------------- */
/* Not required for template or sticky footer method. */

body > .container {
  padding: 60px 15px 0;
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

code {
  font-size: 80%;
}